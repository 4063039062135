<!-- Company info -->
<template>
  <div class="content has_breadcrumb">
    <base-breadcrumb
      :data="[
        {
          routeName: 'companies',
          name: 'Companies',
        },
        {
          name: pageDataList.title,
        },
      ]"
      slot="breadcrumb"
    />
    <GgPage pageType="2" title="Companies">
      <template v-slot:left>
        <gg-flex-menus
          :menus="menuDatas"
          @select="menuSelect"
          :default-active="activeIndex"
        />
      </template>
      <!-- class="mar-b-0" -->
      <GgPageHeader
        :title="pageDataList.title"
        :menuData="menuDataList"
        @menuCallback="handleMenuCallback"
      >
        <i slot="title-btn" class="title-icon iconfont iconedit"></i>
        <div slot="info">
          <div class="account-info">
            <div class="title-status mar-r-8 mar-b-0">
              {{ pageDataList.status }}
            </div>
            <div class="account-create" v-if="activeIndex === 'companyInfo'">
              Created : {{ dateFormat(pageDataList.createTime) }}
            </div>
          </div>
        </div>
      </GgPageHeader>
      <border-less-table
        v-if="activeIndex === 'companyInfo'"
        :data="pageDataList.borderLessData"
      />
      <div v-else-if="activeIndex === 'companyAdmin'">
		  
        <edoovo-table
          height="100%"
          :ref="companyAdminData.ref"
          :data="companyAdminData.tableData"
          rowKey="id"
          defaultValue="-"
          class="list-tableBox"
          v-loading="companyAdminData.isLoading"
          @sortChange="_onSortChangeAdmin"
          :sort="companyAdminData.sort"
        >
          <!-- ===== -->
          <!-- 空状态 -->
          <!-- ===== -->
          <div slot="empty" style="text-align: center">
            <div
              style="
                width: 213px;
                text-align: center;
                border-radius: 50%;
                margin-bottom: 12px;
                line-height: 200px;
              "
            >
              <img
                style="width: 100%"
                :src="require('@/assets/images/products/noData.svg')"
              />
            </div>
            <p style="font-size: 22px">No CompanyAdmin yet.</p>
          </div>
          <!-- =========== -->
          <!-- 每一项column -->
          <!-- =========== -->

          <edoovo-table-column
            size="M"
            label="Name"
            class="user_name"
            prop="name"
            sortName="name"
            :tooltip="true"
            v-slot="{ row }"
          >
            <div>
              <head-img
                class="table-cell-img"
                :src="row.avatar"
                :firstName="row.name"
                :lastName="row.name"
                line-height="32"
              />
              <span class="table-cell-name">{{ `${row.firstName} ${row.lastName}` }}</span>
            </div>
          </edoovo-table-column>
          <edoovo-table-column
            size="L"
            label="Email"
            prop="email"
            sortName="email"
            :tooltip="true"
          />
          <!-- <edoovo-table-column size="M" label="Phone" prop="phone" /> -->
          <edoovo-table-column
            size="M"
            label="Phone"
            prop="phone"
            v-slot="{ row }"
            :getTooltipText="(row) => `${row.countryCode}${row.phoneNumber}`"
          >
            {{ row.countryCode }}{{ row.phoneNumber }}
          </edoovo-table-column>
          <edoovo-table-column
            size="S"
            label="Status"
            prop="status"
            :tooltip="true"
          />
          <edoovo-table-column
            size="L"
            label="Last sign in"
            prop="lastSignTime"
            v-slot="{ row }"
            :getTooltipText="(row) => `${dateTimeFormat(row.lastSignTime)}`"
          >
            {{ dateTimeFormat(row.lastSignTime) }}
          </edoovo-table-column>
          <!-- ====== -->
          <!-- action -->
          <!-- ====== -->
          <edoovo-table-column key="action" v-slot="{ row }">
			  <el-tooltip
			    content="Edit"
			    popper-class="edoovo-table-cell-tooltip"
			    :visible-arrow="false"
			  >
			    <gg-submit-button @click="openDialogPwd1(row)">
			     Edit
			    </gg-submit-button>
			  </el-tooltip>
            <el-tooltip
              content="Reset password"
              popper-class="edoovo-table-cell-tooltip"
              :visible-arrow="false"
            >
              <gg-submit-button @click="openDialogPwd(row)">
                Reset password
              </gg-submit-button>
            </el-tooltip>
			
          </edoovo-table-column>
        </edoovo-table>
      </div>
      <div v-else-if="activeIndex === 'appAccess'">
        <edoovo-table
          height="100%"
          :ref="appAccessData.ref"
          :data="appAccessData.tableData"
          rowKey="ids"
          defaultValue="-"
          class="list-tableBox"
          v-loading="appAccessData.isLoading"
        >
          <!-- ===== -->
          <!-- 空状态 -->
          <!-- ===== -->
          <div slot="empty" style="text-align: center">
            <div
              style="
                width: 213px;
                text-align: center;
                border-radius: 50%;
                margin-bottom: 12px;
                line-height: 200px;
              "
            >
              <img
                style="width: 100%"
                :src="require('@/assets/images/products/noData.svg')"
              />
            </div>
            <p style="font-size: 22px">No CompanyAdmin yet.</p>
          </div>
          <!-- =========== -->
          <!-- 每一项column -->
          <!-- =========== -->

          <edoovo-table-column
            size="L"
            label="Apps"
            prop="moduleName"
            v-slot="{ row }"
            :tooltip="false"
          >
            <img
              class="table-cell-img icon-access"
              :src="
                require(`@/assets/images/menu/${_getSvg(row.moduleName)}.svg`)
              "
            />
            <span class="table-cell-name">{{ row.moduleName }}</span>
          </edoovo-table-column>

          <edoovo-table-column
            size="L"
            label="Status"
            prop="status"
            v-slot="{ row }"
            :tooltip="false"
          >
            <div v-if="!appAccessData.isSwitch">{{ row.status }}</div>
            <gg-switch
              v-else
              v-model="row.switchVal"
              active-color="#1A73E8"
              inactive-color="#5F6368"
			  @change='changeSwitch(row)'
            />
            <span v-if="!appAccessData.isSwitch">{{
              row.switchVal ? "On" : "Off"
            }}</span>
          </edoovo-table-column>
		 
        </edoovo-table>
		<div class="section-bottom gg_dialog" v-if="appAccessData.isSwitch">
			<span>
			    <gg-cancel-button @click='changeAccessSwitch'>Cancel</gg-cancel-button>
			    <gg-submit-button  @click='updateAppAccess'
			 		  :disabled='isChangeSwitch'
			 		  >Save</gg-submit-button>
			  </span>
		</div>
		
		 
      </div>
      <div v-else-if="activeIndex === 'locations'">
        <edoovo-table
          height="100%"
          :ref="locationsData.ref"
          :data="locationsData.tableData"
          rowKey="id"
          defaultValue="-"
          class="list-tableBox"
          v-loading="locationsData.isLoading"
        >
          <!-- ===== -->
          <!-- 空状态 -->
          <!-- ===== -->
          <div slot="empty" style="text-align: center">
            <div
              style="
                width: 213px;
                text-align: center;
                border-radius: 50%;
                margin-bottom: 12px;
                line-height: 200px;
              "
            >
              <img
                style="width: 100%"
                :src="require('@/assets/images/products/noData.svg')"
              />
            </div>
            <p style="font-size: 22px">No Locations yet.</p>
          </div>
          <!-- =========== -->
          <!-- 每一项column -->
          <!-- =========== -->
          <edoovo-table-column
            size="S"
            label="No."
            class="user_name"
            prop="number"
          />
          <edoovo-table-column
            size="M"
            label="Location"
            class="user_name"
            prop="name"
            :tooltip="true"
          />
          <edoovo-table-column
            size="L"
            label="Address"
            prop="address"
            :tooltip="true"
          />
          <edoovo-table-column
            size="L"
            label="Businesses"
            prop="businesses"
            :tooltip="true"
          />
          <edoovo-table-column
            size="M"
            label="Added"
            prop="createTime"
            :tooltip="true"
            v-slot="{ row }"
          >
            {{ dateFormat(row.createTime) }}
          </edoovo-table-column>
        </edoovo-table>
      </div>
    </GgPage>
    <!-- resetpassword dialog -->
    <gg-Dialog
      :visible.sync="formDialogPwd.visible"
      :before-close="cancelResetPwd"
      width="580px"
      ref="resetPassword"
    >
      <div slot="title">
        Reset password for
        <span class="title-bold">{{ formDialogPwd.name }}</span>
      </div>
      <div>
        <ResetPassword
          @sendEmail="resetByEmail"
          :autoSend="formDialogPwd.autoSend"
          v-model="formDialogPwd.data"
        ></ResetPassword>
      </div>
      <span slot="footer" class="dialog-footer">
        <gg-cancel-button @click="cancelResetPwd">Cancel</gg-cancel-button>
        <gg-submit-button
          :disabled="formDialogPwd.isDisabled"
          @click="handleResetPwd"
          >Reset</gg-submit-button
        >
      </span>
    </gg-Dialog>
    <BaseFormDialog1
      v-model="formDialogAdd.visible"
      :title="formDialogAdd.title"
      :data="formDialogAdd.dataList"
      textReminder="* indicates a required field"
      :renderPass="formDialogAdd.renderPass"
      :ref="formDialogAdd.ref"
    >
      <template slot="renderHtml" slot-scope="scope">
        <div
          v-if="scope.data.key === 'logo'"
          :ref="scope.data.key"
          class="upload-imgbox"
          :placeholder="scope.data.placeholder"
        >
          <div class="upload-img" @click="isImgCutShow = true">
            <head-img
              v-if="formDialogAdd.dataRender.logo"
              :src="formDialogAdd.dataRender.logo"
              line-height="88"
            />
            <i v-else class="material-icons iconfont iconadd_a_photo" />
          </div>
        </div>
        <gg-input
          v-else-if="scope.data.key === 'brandName'"
          :key="scope.data.key"
          :ref="scope.data.key"
          :placeholder="scope.data.placeholder"
          v-model="formDialogAdd.dataRender.brandName"
          :error="formDialogAdd.brandNameErrorObj"
        />
        <baseGGSelect
          v-else-if="scope.data.key === 'businesses'"
          v-model="formDialogAdd.dataRender.businesses"
          :ref="scope.data.key"
          :placeholder="scope.data.placeholder"
          multiple
          md-dense
        >
          <md-option
            v-for="(item, index) in scope.data.itemList"
            :key="index"
            :value="item"
          >
            {{ item }}
          </md-option>
        </baseGGSelect>
        <gg-input
          v-else-if="scope.data.key === 'address'"
          filedType="textarea"
          :key="scope.data.key"
          :ref="scope.data.key"
          :placeholder="scope.data.placeholder"
          v-model="formDialogAdd.dataRender.address"
        />
        <gg-input
          v-else-if="scope.data.key === 'companyName'"
          :key="scope.data.key"
          :ref="scope.data.key"
          :placeholder="scope.data.placeholder"
          v-model="formDialogAdd.dataRender.companyName"
          :error="formDialogAdd.companyNameErrorObj"
        />
        <baseGGSelect
          v-else-if="scope.data.key === 'country'"
          v-model="formDialogAdd.dataRender.country"
          :ref="scope.data.key"
          :placeholder="scope.data.placeholder"
          class="float"
        >
          <md-option
            v-for="(item, index) in scope.data.itemList"
            :key="index"
            :value="item"
          >
            {{ item }}
          </md-option>
        </baseGGSelect>
        <!-- todo -->
        <gg-input
          style="margin-bottom: 40px"
          v-else-if="scope.data.key === 'email'"
          :ref="scope.data.key"
          v-model="formDialogAdd.dataRender.email"
          :placeholder="scope.data.placeholder"
          :error="formDialogAdd.emailErrorObj"
        />
        <PhoneNumber
          v-else-if="scope.data.key === 'phone'"
          v-model="formDialogAdd.dataRender.phone"
          :ref="scope.data.key"
          :placeholder="scope.data.placeholder"
        ></PhoneNumber>
        <div
          v-else-if="scope.data.key === 'organizationCertificate'"
          class="mar-b-20 gg_input"
        >
          <div class="upload-describ mar-b-10">
            {{ scope.data.placeholder }}
          </div>
          <el-upload
            class="upload-wrapper"
            ref="uploadFileInp"
            action=""
            accept="image/jpeg,image/png,application/pdf"
            :auto-upload="false"
            list-type="picture"
            :file-list="scope.data.itemList"
            :on-change="uploadFileChange"
          >
            <template
              v-if="formDialogAdd.dataRender.organizationCertificate"
              slot="file"
              slot-scope="{ file }"
            >
              <i v-if="file.type === 'pdf'" class="el-icon-document "></i>
              <img
                v-else
                class="upload-img"
                :src="formDialogAdd.dataRender.organizationCertificate"
                alt=""
                @click="handlePreview(file)"
              />
              <p class="upload-txt" @click="handlePreview(file)">
                {{ `File name : ${file.name}` }}
              </p>
              <i class="el-icon-close" @click="handleRemove"></i>
            </template>
            <div
              v-if="!formDialogAdd.dataRender.organizationCertificate"
              class="upload-btn"
            >
              Add file
            </div>
          </el-upload>
        </div>
      </template>
      <template slot="footer">
        <baseButton @click="handleCancelForm">Cancel</baseButton>
        <baseButton
          type="primary"
          :disabled="!formDialogAdd.isDisabled"
          @click="handleAddForm"
          >{{ formDialogAdd.submitBtnTxt }}</baseButton
        >
      </template>
    </BaseFormDialog1>
	<BaseFormDialog1
	  v-model="formDialogAdd2.visible"
	  :title="formDialogAdd2.title"
	  :data="formDialogAdd2.dataList"
	  textReminder="* indicates a required field"
	  :renderPass="formDialogAdd2.renderPass"
	  :ref="formDialogAdd2.ref"
	>
	  <template slot="renderHtml" slot-scope="scope">
	    <div
	      v-if="scope.data.key === 'logo'"
	      :ref="scope.data.key"
	      class="upload-imgbox"
	      :placeholder="scope.data.placeholder"
	    >
	      <div class="upload-img" @click="isImgCutShow1 = true">
	        <head-img
	          v-if="formDialogAdd2.dataRender.logo"
	          :src="formDialogAdd2.dataRender.logo"
	          line-height="88"
	        />
	        <i v-else class="material-icons iconfont iconadd_a_photo" />
	      </div>
	    </div>
	 <gg-input
	   v-else-if="scope.data.key === 'firstname'"
	   :key="scope.data.key"
	   :ref="scope.data.key"
	   :placeholder="scope.data.placeholder"
	   v-model="formDialogAdd2.dataRender.firstname"
	   :error="formDialogAdd2.firstnameErrorObj"
	 />
	 <gg-input
	   v-else-if="scope.data.key === 'lastname'"
	   :key="scope.data.key"
	   :ref="scope.data.key"
	   :placeholder="scope.data.placeholder"
	   v-model="formDialogAdd2.dataRender.lastname"
	   :error="formDialogAdd2.lastnameErrorObj"
	 />
	    
	    
	    <!-- todo -->
	    <gg-input
	      style="margin-bottom: 40px"
	      v-else-if="scope.data.key === 'email'"
	      :ref="scope.data.key"
	      v-model="formDialogAdd2.dataRender.email"
	      :placeholder="scope.data.placeholder"
	      :error="formDialogAdd2.emailErrorObj"
	    />
	    <PhoneNumber
	      v-else-if="scope.data.key === 'phone'"
	      v-model="formDialogAdd2.dataRender.phone"
	      :ref="scope.data.key"
	      :placeholder="scope.data.placeholder"
	    ></PhoneNumber>
	   
	  </template>
	  <template slot="footer">
	    <baseButton @click="handleCancelForm2">Cancel</baseButton>
	    <baseButton
	      type="primary"
	      :disabled="!formDialogAdd2.isDisabled"
	      @click="handleAddForm2"
	      >{{ formDialogAdd2.submitBtnTxt }}</baseButton
	    >
	  </template>
	</BaseFormDialog1>
    <imgCutModal
      :visible.sync="isImgCutShow"
      v-if="isImgCutShow"
      :fixed-number="[16,10]"
      @_uploadCallback="_uploadCallback"
    />
	<imgCutModal
	  :visible.sync="isImgCutShow1"
	  v-if="isImgCutShow1"
	  :fixed-number="[16,10]"
	  @_uploadCallback="_uploadCallback1"
	/>
    <Snackbar
      :visible.sync="snackbarData.visible"
      :content="snackbarData.content"
    />
  </div>
</template>

<script>
import { Ajax } from "@/common";
import { mapState } from "vuex";
import { menuIconParams } from "@/common/baseData";
import moment from "moment";
export default {
  components: {},
  data() {
    return {
		isImgCutShow1:false,
			firstData:{},
		isChangeSwitch:true,
      query: {
        size: 20,
        current: 1,
      },
      id: "",
      menuDatas: [
        {
          name: "Company info",
          key: "companyInfo",
          active: true,
          children: [],
        },
        {
          name: "Company Admin",
          key: "companyAdmin",
        },
        {
          name: "App access",
          key: "appAccess",
        },
        {
          name: "Locations",
          key: "locations",
        },
      ],
      activeIndex: "companyInfo",
      menuDataList: ["Edit", "Suspend", "Close"],
      pageDataList: {
        title: "",
        status: "",
        createTime: "",
        borderLessData: [
          { label: "Logo", value: "", id: "logo" },
          { label: "Brand name", value: "", id: "brandName" },
          { label: "Businesses", value: "", id: "businesses" },
          { label: "Company ID", value: "", id: "companyId" },
          { label: "Company name", value: "", id: "companyName" },
          { label: "Registration no.", value: "", id: "registrationNo" },
          {
            label: "Organization certificate",
            value: "",
            id: "organizationCertificate",
          },
          { label: "Address", value: "", id: "address" },
          { label: "Address line 2", value: "", id: "addressLine2" },
          { label: "Postal code", value: "", id: "postalCode" },
          { label: "Country", value: "", id: "country" },
          { label: "Email", value: "", id: "email" },
          { label: "Phone number", value: "", id: "phoneNumber" },
        ],
      },
      userInfo: {},
      isImgCutShow: false,
      formDialogAdd: {
        ref: "formDialogAdd",
        visible: false,
        title: "",
        add: "Add new company",
        edit: "Edit company",
        submitBtnTxt: "Add",
        renderPass: false,
        slotData: ["brandName", "companyName", "email", "country"],
        isDisabled: true,
        brandNameErrorObj: {
          show: false,
          message: "",
        },
        companyNameErrorObj: {
          show: false,
          message: "",
        },
        emailErrorObj: {
          show: false,
          message: "",
        },
        dataRender: {
          logo: "",
          brandName: "",
          businesses: [],
          address: "",
          companyName: "",
          country: "",
          email: "",
          phone: "",
          organizationCertificate: "",
        },
        dataList: [
          {
            placeholder: "logo",
            key: "logo",
            value: "",
            renderHtml: true,
          },
          {
            placeholder: "Brand name *",
            key: "brandName",
            value: "",
            renderHtml: true,
          },
          {
            placeholder: "Businesses *",
            key: "businesses",
            value: [],
            itemList: [],
            renderHtml: true,
          },
          {
            placeholder: "Company name *",
            key: "companyName",
            value: "",
            renderHtml: true,
          },
          {
            placeholder: "Address *",
            key: "address",
            value: "",
            renderHtml: true,
          },
          {
            placeholder: "Address line 2",
            key: "addressLine2",
            value: "",
          },
          {
            placeholder: "Postal code *",
            key: "postalCode",
            value: "",
            float: true,
            reg: /[\S]/,
            errorText: "postalCode is required",
          },
          {
            placeholder: "Country *",
            key: "country",
            value: "",
            float: true,
            itemList: [],
            renderHtml: true,
          },
          {
            placeholder: "Email *",
            key: "email",
            value: "",
            renderHtml: true,
          },
          // todo
          {
            placeholder: "Phone number *",
            key: "phone",
            value: {
              countryCode: "+65",
              phoneNumber: "",
            },
            renderHtml: true,
          },
          {
            placeholder: "certificateFile",
            key: "organizationCertificate",
            itemList: [],
            value: "",
            isUpload: true,
            renderHtml: true,
          },
        ],
      },
      companyAdminData: {
        ref: "companyAdminTable",
        isLoading: false,
        sort: { name: "", type: "" },
        query: {},
        tableData: [],
      },
      appAccessData: {
        ref: "appAccessTable",
        isLoading: false,
        tableData: [
          { moduleName: "Admin Console", switchVal: false, id: "" },
          { moduleName: "Staff", switchVal: false, id: "" },
          { moduleName: "Students", switchVal: false, id: "" },
          { moduleName: "People", switchVal: false, id: "" },
          { moduleName: "Classes", switchVal: false, id: "" },
          { moduleName: "Attendance", switchVal: false, id: "" },
          { moduleName: "Announcements", switchVal: false, id: "" },
          { moduleName: "Commbook", switchVal: false, id: "" },
          { moduleName: "Moments", switchVal: false, id: "" },
		   { moduleName: "Journal", switchVal: false, id: "" },
        ],
        isSwitch: false,
      },
      locationsData: {
        ref: "locationsTable",
        isLoading: false,
        sort: { name: "createDate", type: "desc" },
        tableData: [{ firstName: "222", lastName: "222" }],
      },
      formDialogPwd: {
        visible: false,
        data: "",
        autoSend: true,
        name: "",
        id: "",
        isDisabled: false,
      },
      snackbarData: {
        visible: false,
        content: "",
      },
	  formDialogAdd2: { //新流程 0712 cy留
	    ref: "formDialogAdd2",
	    visible: false,
	    title: "Add company admin",
	    add: "Add new company",
	    edit: "Edit company",
	    submitBtnTxt: "Save",
	    renderPass: false,
	    slotData: ["firstname", "lastname","email"],
	    isDisabled: true,
	    firstnameErrorObj: {
	      show: false,
	      message: "",
	    },
	    lastnameErrorObj: {
	      show: false,
	      message: "",
	    },
	    emailErrorObj: {
	      show: false,
	      message: "Invalid email address",
	    },
	    dataRender: {
	  		  logo: "",
	  		  lastname:"",
	  		  firstname:"",
	      email: "",
	      phone: ""
	    },
	    dataList: [
	      {
	        placeholder: "logo",
	        key: "logo",
	        value: "",
	        renderHtml: true,
	      },
	      {
	        placeholder: "First name *",
	        key: "firstname",
	        value: "",
	        renderHtml: true,
	      },
	      {
	        placeholder: "Last name *",
	        key: "lastname",
	        value: "",
	        renderHtml: true,
	      },
	      {
	        placeholder: "Email *",
	        key: "email",
	        value: "",
	        renderHtml: true,
	  			 reg: /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
	  			    errorText: "Invalid email address",
	      },
	      // todo
	      {
	        placeholder: "Phone number *",
	        key: "phone",
	        value: {
	          countryCode: "+65",
	          phoneNumber: "",
	        },
	        renderHtml: true,
	      }
	      // {
	      //   placeholder: "Organization certificate",
	      //   key: "organizationCertificate",
	      //   itemList: [],
	      //   value: "",
	      //   isUpload: true,
	      //   renderHtml: true,
	      // },
	    ],
	  }
    };
  },
  watch: {
    "formDialogPwd.data": function (res) {
      this.formDialogPwd.isDisabled = res.length < 8;
    },
    "formDialogAdd.dataRender.companyName": function (res) {
      if (res.length === 0) {
        this.formDialogAdd.companyNameErrorObj = {
          show: true,
          message: "Company name required",
        };
      } else if (res.length > 80) {
        this.formDialogAdd.companyNameErrorObj = {
          show: true,
          message: "Character limit of 80",
        };
      } else {
        this.formDialogAdd.companyNameErrorObj = {
          show: false,
          message: "",
        };
      }
    },
    "formDialogAdd.dataRender.brandName": function (res) {
      if (res.length === 0) {
        this.formDialogAdd.brandNameErrorObj = {
          show: true,
          message: "Brand name required",
        };
      } else if (res.length > 80) {
        this.formDialogAdd.brandNameErrorObj = {
          show: true,
          message: "Character limit of 80",
        };
      } else {
        this.formDialogAdd.brandNameErrorObj = {
          show: false,
          message: "",
        };
      }
    },
    "formDialogAdd.dataRender.email": function (res) {
      let isPass = /^(\w)+(\.\w+)*@(\w)+((\.\w+)+)$/.test(res);
      if (res.length === 0) {
        this.formDialogAdd.emailErrorObj = {
          show: true,
          message: "Email required",
        };
      } else if (!isPass) {
        this.formDialogAdd.emailErrorObj = {
          show: true,
          message: "Email address is invalid",
        };
      } else {
        this.formDialogAdd.emailErrorObj = {
          show: false,
          message: "",
        };
      }
    },
	"formDialogAdd2.dataRender.email": function (res) {
	  let isPass = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(res);
	  if (res.length === 0) {
	    this.formDialogAdd2.emailErrorObj = {
	      show: true,
	      message: "Email required",
	    };
	  } else if (!isPass) {
	    this.formDialogAdd2.emailErrorObj = {
	      show: true,
	      message: "Email address is invalid",
	    };
	  } else {
	    this.formDialogAdd2.emailErrorObj = {
	      show: false,
	      message: "",
	    };
	  }
	},
    formDialogAdd: {
      deep: true,
      handler(res) {
        this.$nextTick(() => {
          const isRender = res.slotData.every((item) => {
            return (
              this.$refs[item] &&
              !!this.$refs[item].value &&
              !this.$refs[item].error.show
            );
          });
          const isForm = this.$refs.formDialogAdd.valid(); // 表单其他非插槽元素是否验证通过
          let isPhone = false;
          let businessesPass =
            this.$refs.businesses && this.$refs.businesses.value.length > 0;

          if (this.$refs.phone) {
            isPhone =
              this.$refs.phone &&
              !this.$refs.phone.isError.show &&
              !!this.$refs.phone.value.phoneNumber;
          }

          res.renderPass = res.isDisabled =
            isRender &&
            isForm &&
            isPhone &&
            businessesPass &&
            !this.$refs.email.error.show;
        });
      },
    },
	formDialogAdd2: {
      deep: true,
      handler(res) {
        this.$nextTick(() => {
          const isRender = res.slotData.every((item) => {
            return (
              this.$refs[item] &&
              !!this.$refs[item].value &&
              !this.$refs[item].error.show
            );
          });
          // const isForm = this.$refs.formDialogAdd2.valid(); // 表单其他非插槽元素是否验证通过
          let isPhone = false;
  

          if (this.$refs.phone) {
            isPhone =
              this.$refs.phone &&
              !this.$refs.phone.isError.show &&
              !!this.$refs.phone.value.phoneNumber;
          }
		  
          res.renderPass = res.isDisabled =
            isRender &&
            // isForm &&
            isPhone &&
            !this.$refs.email.error.show;
        });
      },
    },
  },
  computed: {
    ...mapState({
      isAdmin: (state) => state.user.isAdmin, //  1_admin
      userId: (state) => state.user.userId,
    }),
  },
  methods: {
	  fillData1() {
		  // 格式化管理员信息
	    let obj = {};
	    let formData = Object.assign({}, obj, this.formDialogAdd2.dataRender);
	  
	    let { phoneNumber, countryCode } = this.formDialogAdd2.dataRender.phone;
	    formData.phoneNumber = phoneNumber;
	    formData.countryCode = countryCode;
	    return formData;
	  },
	  handleCancelForm2() {
	    this.formDialogAdd2.visible = false;
	  },
	  handleAddForm2(res) {
		  let params = {};
		  		params = Object.assign({},this.fillData1(),{
		  		  userId: this.userId,
		  		});
		  		if(params.logo){
		  			params.avatar = params.logo
		  		}
		  		if(params.firstname){
		  			params.firstName = params.firstname
		  		}
		  		if(params.lastname){
		  			params.lastName = params.lastname
		  		}
				params.id = this.id
		  Ajax.post("/usermanage/company/updateCompanyAdminUser", params).then(
		    (res) => {
		      if (res.code === "0000") {
				this._getDatacompanyAdmin()
		        this.handleSnack("CompanyAdmin has been saved");
		        this.formDialogAdd2.visible = false;
		      } else {
		        if (res.message === "Email already exists") {
		          this.formDialogAdd2.emailErrorObj = {
		            show: true,
		            message: res.message,
		          };
		        }
		      }
		    }
		  );
	    // if (this.formDialogAdd.title === this.formDialogAdd.edit) {
	    //   this.handleEditSubmit();
	    // } else {
	    //   this.handleAddSubmit();
	    // }
	  },
	  changeAccessSwitch(){
		this.appAccessData.isSwitch = false 
		this.menuDataList = ['Edit']  
	  },
    dateFormat(date) {
      if (!date) {
        return "-";
      }
      return moment.utc(date).local().format("DD/MM/YYYY");
    },
    dateTimeFormat(date) {
      if (!date) {
        return "-";
      }
      return moment.utc(date).local().format("DD/MM/YYYY HH:MM");
    },
    _getSvg(menu) {
      if (menuIconParams[menu] && menuIconParams[menu].svg) {
        return menuIconParams[menu].svg;
      }
      return "product_app";
    },
    menuSelect(key, item) {
      this.activeIndex = key;
      if (key !== "appAccess") {
        this.appAccessData.isSwitch = false;
      }

      switch (key) {
        case "companyInfo":
			if(this.pageDataList.status=='active'){
				 this.menuDataList = ["Edit", "Suspend", "Close"];
			}
			else if(this.pageDataList.status=='suspend'){
				this.menuDataList = ["Reactivate"];
			}else if(this.pageDataList.status=='closed'){
				this.menuDataList = ["Restore"];
			}else {
				this.menuDataList = [];
			}
          this._getData();
          break;
        case "companyAdmin":
          this.menuDataList = [];
          this._getDatacompanyAdmin();
          break;
        case "locations":
          this.menuDataList = [];
          this._getDataLocations();
          break;
        case "appAccess":
		if(this.pageDataList.status=='active'){
			 this.menuDataList = ["Edit"];
		}
		else if(this.pageDataList.status=='suspended'){
			this.menuDataList = [];
		}else if(this.pageDataList.status=='closed'){
			this.menuDataList = [];
		}else {
			this.menuDataList = [];
		}
          // this.menuDataList = ["Edit"];
          this._getDataAppAccess();
          break;
        default:
	if(this.pageDataList.status=='active'){
		 this.menuDataList = ["Edit", "Suspend", "Close"];
	}
	else if(this.pageDataList.status=='suspended'){
		this.menuDataList = [ "Reactivate"];
	}else if(this.pageDataList.status=='closed'){
		this.menuDataList = [ "Restore"];
	}else {
		this.menuDataList = [];
	}
          break;
      }
    },
    _getData() {
      Ajax.post(`/usermanage/company/getById/${this.id}`).then((res) => {
        let { companyName, createTime, status } = res.data;
        this.userInfo = { ...res.data };
        this.pageDataList.title = companyName;
        this.pageDataList.status = status;
		this.companyUserId = res.data.userId
		if(this.pageDataList.status=='active'){
			 this.menuDataList = ["Edit", "Suspend", "Close"];
		}
		else if(this.pageDataList.status=='suspended'){
			this.menuDataList = ["Reactivate"];
		}else if(this.pageDataList.status=='closed'){
			this.menuDataList = ["Restore"];
		}else {
			this.menuDataList = [];
		}
        this.pageDataList.createTime = createTime;
        Object.keys(res.data).map((k, index) => {
          this.pageDataList.borderLessData.map((i) => {
            if (i.id == k) {
              i.value = res.data[k];
            }
			if(i.value == '' || i.value == null){
						  i.value = '-'
			}
          });
        });
      });
    },
    fillData() {
      let obj = {};
      this.$refs.formDialogAdd.dataExceptRender.forEach((item) => {
        obj[item.key] = item.value;
      });
      let formData = Object.assign({}, obj, this.formDialogAdd.dataRender);

      let { phoneNumber, countryCode } = this.formDialogAdd.dataRender.phone;
      formData.phoneNumber = phoneNumber;
      formData.countryCode = countryCode;
      return formData;
    },
    _uploadCallback(formData, fileData) {
      formData.append("multipartFile", fileData.raw);
      Ajax.post("/media/public/file/upload", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((res) => {
          this.formDialogAdd.dataList.find(
            (item) => item.key === "logo"
          ).value = `${process.env.VUE_APP_fileBasePath}${res.data.filePath}`;
		  this.submitImgEdit()
          this.isImgCutShow = false;
        })
        .catch(() => {
          this.isImgCutShow = false;
        });
    },
	_uploadCallback1(formData, fileData) {
	  formData.append("multipartFile", fileData.raw);
	  Ajax.post("/media/public/file/upload", formData, {
	    headers: { "Content-Type": "multipart/form-data" },
	  })
	    .then((res) => {
	    this.formDialogAdd2.dataRender.logo = `${process.env.VUE_APP_fileBasePath}${res.data.filePath}`;
	    this.isImgCutShow1 = false;
	    })
	    .catch(() => {
	      this.isImgCutShow = false;
	    });
	},
    openDialogAdd() {
      this.formDialogAdd.title = this.formDialogAdd.edit;
      this.formDialogAdd.submitBtnTxt = "Save";
      this.formDialogAdd.id = this.id;
      this.formDialogAdd.dataList.forEach((i) => {
        if (this.userInfo[i.key]) {
          i.value = this.userInfo[i.key];
        }
        if (i.key === "organizationCertificate") {
          if (this.userInfo["organizationCertificate"]) {
            i.itemList = [];
            let reg = /\.([0-9a-z]+)(?:[\?#]|$)/i;
            let fileName = this.userInfo["organizationCertificate"].split("/");
            let fileFormat = fileName[fileName.length - 1].match(reg)[1];
            i.itemList.push({
              name: fileName[fileName.length - 1],
              url: this.userInfo["organizationCertificate"],
              type: fileFormat,
            });
          } else {
            i.itemList = [];
          }
        }
      });

      Object.keys(this.formDialogAdd.dataRender).forEach((item) => {
        this.formDialogAdd.dataRender[item] = this.userInfo[item];
      });

      this.formDialogAdd.dataRender.phone = {
        countryCode: this.userInfo["countryCode"],
        phoneNumber: this.userInfo["phoneNumber"],
      };

      this.formDialogAdd.dataRender.businesses = this.userInfo[
        "businesses"
      ].split(",");

      this.formDialogAdd.visible = true;
    },
    handleCancelForm() {
      this.formDialogAdd.visible = false;
    },
	submitImgEdit(){
		let logo =''
	
		this.formDialogAdd.dataList.forEach((item)=>{
			if(item.key === "logo"){
				logo=item.value
			}
		})
		let params = {
			// userId: this.userId,
			 userId: this.companyUserId,
			id: this.id,
			creater:this.userId,
			logo:logo
		};
		
	
		
		Ajax.post("/usermanage/company/updateCompany", params).then((res) => {
		  if (res.code === "0000") {
		    this._getData();
		    this.handleSnack("Company has been edited");
		    // this.formDialogAdd.visible = false;
			 this.formDialogAdd.dataRender.logo=logo
		  } else {
		    if (res.message === "Email already exists") {
		      this.formDialogAdd.emailErrorObj = {
		        show: true,
		        message: res.message,
		      };
		    }
		
		    if (res.message === "Brand name already exists") {
		      this.formDialogAdd.brandNameErrorObj = {
		        show: true,
		        message: res.message,
		      };
		    }
		
		    if (res.message === "Company name already exists") {
		      this.formDialogAdd.companyNameErrorObj = {
		        show: true,
		        message: res.message,
		      };
		    }
		  }
		});
	},
    handleAddForm(res) {
      let params = {};

      params = Object.assign({}, this.fillData(), {
        // userId: this.userId,
        userId: this.companyUserId,
		 // userId:'AAAA',
        id: this.id,
		creater:this.userId
      });

      Ajax.post("/usermanage/company/updateCompany", params).then((res) => {
        if (res.code === "0000") {
          this._getData();
          this.handleSnack("Company has been edited");
          this.formDialogAdd.visible = false;
		  // this.openDialogAdd()
        } else {
          if (res.message === "Email already exists") {
            this.formDialogAdd.emailErrorObj = {
              show: true,
              message: res.message,
            };
          }

          if (res.message === "Brand name already exists") {
            this.formDialogAdd.brandNameErrorObj = {
              show: true,
              message: res.message,
            };
          }

          if (res.message === "Company name already exists") {
            this.formDialogAdd.companyNameErrorObj = {
              show: true,
              message: res.message,
            };
          }
        }
      });
    },
    _getDatacompanyAdmin() {
      this.companyAdminData.isLoading = true;
      Ajax.post(
        `/usermanage/company/getUserByType/${this.id}`,
        this.companyAdminData.query
      )
        .then((res) => {
          if (res.code === "0000") {
            this.companyAdminData.isLoading = false;
            this.companyAdminData.tableData = res.data;
          }
        })
        .catch((err) => {
          this.usersData.isLoading = false;
        });
    },
    _getDataAppAccess() {
      this.appAccessData.isLoading = true;
      Ajax.get("/usermanage/superAdmin/selectCompanyPermissionByCompanyId", {
        companyId: this.id,
      }).then((res) => {
        if (res.code && res.code === "0000") {
          
          this.appAccessData.isLoading = false;
          res.data.forEach((item) => {
            this.appAccessData.tableData.forEach((apps) => {
              if (item.moduleName === apps.moduleName) {
				  if(item.hasModule==1){
					    apps.switchVal = true;
				  }else{
					  apps.switchVal = false
				  }
              
                apps.id = item.id;
              }
            });
			
          });
        }
      });
    },
    _getDataLocations() {
      this.locationsData.isLoading = true;
      Ajax.get("/usermanage/location/selectLocationByCompanyId", {
        id: this.id,
      }).then((res) => {
        if (res.code && res.code === "0000") {
          this.locationsData.isLoading = false;
          this.locationsData.tableData = res.data;

          let i = 1;
          this.locationsData.tableData.forEach((item) => {
            item.number = (this.query.current - 1) * this.query.size + i++;
          });
        }
      });
    },
    _onSortChangeAdmin(sort) {
      this.companyAdminData.sort = sort;
      this.companyAdminData.query.sortName = sort.name;
      this.companyAdminData.query.sortType = sort.type;
      this._getDatacompanyAdmin();
    },
    handleSnack(content) {
      this.snackbarData.content = content;
      this.snackbarData.visible = true;
    },
    resetByEmail(val) {
      this.formDialogPwd.autoSend = val;
      if (val || this.formDialogPwd.data.length > 8) {
        this.formDialogPwd.isDisabled = false;
      } else {
        this.formDialogPwd.isDisabled = true;
      }
    },
    openDialogPwd(row) {
      this.formDialogPwd.name = row.name;
      this.formDialogPwd.id = row.id;
      this.formDialogPwd.autoSend = true;
      this.formDialogPwd.isDisabled = false;
      this.formDialogPwd.visible = true;
    },
	openDialogPwd1(row) {
	  // this.formDialogPwd.name = row.name;
	  // this.formDialogPwd.id = row.id;
	  // this.formDialogAdd.title = this.formDialogAdd.edit;
	  this.formDialogAdd.submitBtnTxt = "Save";
	  this.formDialogAdd2.id = row.id;
	  this.formDialogAdd2.dataList.forEach((i) => {
	    i.value = row[i.key];
	  });
	  
	  Object.keys(this.formDialogAdd2.dataRender).forEach((item) => {
	    this.formDialogAdd2.dataRender[item] = row[item];
	  });
	  
	  this.formDialogAdd2.dataRender.phone = {
	    countryCode: row["countryCode"],
	    phoneNumber: row["phoneNumber"],
	  };
	  this.formDialogAdd2.dataRender.firstname = row.firstName
	   this.formDialogAdd2.dataRender.lastname = row.lastName
	   this.formDialogAdd2.dataRender.logo = row.avatar
	  console.log(this.formDialogAdd2.dataRender)
	   this.formDialogAdd2.visible = true;
	  // this.formDialogPwd.visible = true;
	},
    cancelResetPwd() {
      this.formDialogPwd.visible = false;
      this.formDialogPwd.data = "";
    },
    handleResetPwd() {
      let isAuto = this.formDialogPwd.autoSend ? "1" : "0";
      this.formDialogPwd.visible = false;
      Ajax.post("/usermanage/password/resetPasswd", {
        userId: this.formDialogPwd.id,
        newPasswd: this.formDialogPwd.data,
        isAuto: isAuto,
      }).then((res) => {
        if (res.code === "0000") {
          this.handleSnack("Password has been reset");
          this.formDialogPwd.data = "";
        }
      });
    },
    handleRemove() {
      let organizationCertificate = this.formDialogAdd.dataList.find(
        (item) => item.key === "organizationCertificate"
      );
      organizationCertificate.itemList = [];
      this.formDialogAdd.dataRender.organizationCertificate = "";
    },
    handlePreview(file) {
      const needPrint = 1; // 是否需要展示打印按钮
      let reg = /\.([0-9a-z]+)(?:[\?#]|$)/i;
      let fileFormat = file.name.match(reg)[1];

      if (fileFormat === "pdf") {
        window.open(
          `/testFileView?url=${file.url}&name=${file.name}&${needPrint}`
        );
      } else {
        window.open(
          `/testFileView?imgUrl=${file.url}&name=${file.name}${needPrint}`
        );
      }
    },
    uploadFileChange(file, fileList) {
      if (file) {
        let organizationCertificate = this.formDialogAdd.dataList.find(
          (item) => item.key === "organizationCertificate"
        );

        organizationCertificate.isUpload = false;
        organizationCertificate.itemList = [];

        let formData = new FormData();
        formData.append("file", file);
        formData.append("multipartFile", file.raw);
        Ajax.post("/media/public/file/uploadExt", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
          .then((res) => {
            if (res.code === "0000") {
              let reg = /\.([0-9a-z]+)(?:[\?#]|$)/i;
              let fileFormat = res.data.filePath.match(reg)[1];
              this.formDialogAdd.dataRender.organizationCertificate = `${process.env.VUE_APP_fileBasePath}${res.data.filePath}`;
              organizationCertificate.itemList.push({
                name: file.name,
                url: `${process.env.VUE_APP_fileBasePath}${res.data.filePath}`,
                type: fileFormat,
              });
			  
            }
            if (res.code === "9999") {
              this.handleSnack("Maximum upload size exceeded");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    handleMenuCallback(item) {
      if (item === "Edit" && this.activeIndex === "companyInfo") {
        this.openDialogAdd();
      }
      if (item === "Edit" && this.activeIndex === "appAccess") {
        this.appAccessData.isSwitch = true;
        this.menuDataList = [];
      }
      if (item === "Suspend") {
        this.handleSuspend();
      }
      if (item === "Close") {
        this.handleClose();
      }
	  if (item === "Reactivate") {
	    this.handleReactive();
	  }
    if (item == "Restore") {
	    this.handleRestore();
	  }
    },
	handleReactive() {
	  this.$confirm(
	    "Once reactivated, the company will be able to access the apps.",
	    "Reactivate company account ?",
	    {
	      cancelButtonText: "Cancel",
	      confirmButtonText: "Reactivate",
	      cancelButtonClass: "gg_cancel_button",
	      confirmButtonClass: "gg_submit_button",
	      customClass: "gg_confirm",
        showClose: false,
	    }
	  )
	    .then(() => {
	      Ajax.post("/usermanage/company/updateCompanyStatus", {
	        status: "active",
	        id: this.id,
	      }).then((res) => {
	        if (res.code === "0000") {
	          this._getData();
	          this.handleSnack("Company account has been reactivated");
	        }
	      });
	    })
	    .catch(() => {});
	},
  handleRestore() {
	  this.$confirm(
	    "Once restored, the company will be able to log in the platform and recover the data.",
	    "Restore company account ?",
	    {
	      cancelButtonText: "Cancel",
	      confirmButtonText: "Restore",
	      cancelButtonClass: "gg_cancel_button",
	      confirmButtonClass: "gg_submit_button",
	      customClass: "gg_confirm",
        showClose: false,
	    }
	  )
	    .then(() => {
	      Ajax.post("/usermanage/company/updateCompanyStatus", {
	        status: "active",
	        id: this.id,
	      }).then((res) => {
	        if (res.code === "0000") {
	          this._getData();
	          this.handleSnack("Company account has been restored");
	        }
	      });
	    })
	    .catch(() => {});
	},
    handleSuspend() {
      this.$confirm(
        "Once suspended, the company will not be able to access the apps.",
        "Suspend company account ?",
        {
          cancelButtonText: "Cancel",
          confirmButtonText: "Suspend",
          cancelButtonClass: "gg_cancel_button",
          confirmButtonClass: "gg_submit_button",
          customClass: "gg_confirm",
          showClose: false,
        }
      )
        .then(() => {
          Ajax.post("/usermanage/company/updateCompanyStatus", {
            status: "suspended",
            id: this.id,
          }).then((res) => {
            if (res.code === "0000") {
              this._getData();
              this.handleSnack("Company account has been suspended");
            }
          });
        })
        .catch(() => {});
    },
    handleClose() {
      this.$confirm(
        "Once closed, the company will not be able to log in the platform.",
        "Close company account ?",
        {
          cancelButtonText: "Cancel",
          confirmButtonText: "Close",
          cancelButtonClass: "gg_cancel_button",
          confirmButtonClass: "gg_submit_button",
          customClass: "gg_confirm",
          showClose: false,
        }
      )
        .then(() => {
          Ajax.post("/usermanage/company/updateCompanyStatus", {
            status: "closed",
            id: this.id,
          }).then((res) => {
            if (res.code === "0000") {
              this._getData();
              this.handleSnack("Company account has been closed");
            }
          });
        })
        .catch(() => {});
    },
	changeSwitch(item){
		this.isChangeSwitch=false
		this.appAccessData.tableData.forEach((apps) => {
		  if (item.moduleName === apps.moduleName) {
				    apps.switchVal = item.switchVal
		  }
		});
		
	},
    updateAppAccess() {
		let permissionsArray=[]
		this.appAccessData.tableData.forEach((apps) => {
		  apps.hasModule=apps.switchVal ? "1" : "0";
		  if(apps.switchVal){
			  permissionsArray.push(apps)
		  }
		});
      let params = {
		permissionsArray:permissionsArray,
        companyId: this.id
      };
      Ajax.post("/usermanage/superAdmin/updateAppAccess", params).then(
        (res) => {
          if (res.code === "0000") {
            this._getDataAppAccess();
			this.appAccessData.isSwitch=false
			this.menuDataList = ['Edit'];
			this.isChangeSwitch=true
			this.handleSnack("Success");
          }
        }
      );
    },
    getSettingList(dataLevel, dataType) {
      let params = {
        dataLevel: dataLevel,
        dataType: dataType,
        userId: this.userId,
      };
      Ajax.post("/usermanage/setting/getList", params).then((res) => {
        let arr = [];
        res.data.forEach((item1) => {
          this.formDialogAdd.dataList.forEach((item2) => {
            if (item1.dataType === item2.key) {
              item2.itemList.push(item1.value);
            }
          });
        });
      });
    },
  },
  created() {
    this.id = this.$route.query.id;
    this._getData();
    this.getSettingList("companies", "businesses");
    this.getSettingList("general", "country");
  },
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.title-status {
  text-transform: capitalize;
}
.icon-access{
  height: 24px;
  width: auto;
  max-width: 24px;
}
.section-bottom{
		height: 68px;
		    padding: 20px;
		    flex-shrink: 0;
		    display: flex;
		    justify-content: flex-end;
		    border-top: 1px solid #e0e0e0;
	}
/deep/.el-image img{
		height: 100%;
		object-fit: cover!important;
	}
	.gg_confirm .el-button.gg_cancel_button,
	.gg_confirm .el-button.gg_cancel_button:focus,
	.gg_dialog .el-button.gg_cancel_button,
	.gg_dialog .el-button.gg_cancel_button:focus {
	  color: #5f6368;
	  font-family: OpenSans-Bold;
	  font-size: 13px;
	  border-radius: 4px;
	  height: 32px;
	  line-height: 32px;
	  padding: 0 12px;
	  background: transparent;
	  border: none;
	}
	
	.gg_confirm .el-button.gg_cancel_button:hover,
	.gg_dialog .el-button.gg_cancel_button:hover {
	  background: #eeeeee;
	}
	
	.gg_confirm .el-button.gg_cancel_button.is-disabled,
	.gg_confirm .el-button.gg_cancel_button.is-disabled:hover,
	.gg_confirm .el-button.gg_cancel_button.is-disabled:focus,
	.gg_dialog .el-button.gg_cancel_button.is-disabled,
	.gg_dialog .el-button.gg_cancel_button.is-disabled:hover,
	.gg_dialog .el-button.gg_cancel_button.is-disabled:focus {
	  background: transparent;
	  color: #b4b4b4;
	}
</style>